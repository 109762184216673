<template>
  <b-modal
    :id="`modal-create-plans`"
    centered
    size="xl"
    no-close-on-backdrop
    hide-footer
    class="p-0"
    @shown="openModal"
  >
    <template #modal-header>
      <language-selector-header
        :title="
          item.key
            ? $t('backoffice.subscriptions.form.title-edit')
            : $t('backoffice.subscriptions.form.title')
        "
        @closeModal="closeModal"
        @selectLanguage="(language) => (selectedLanguage = language)"
      />
    </template>
    <div class="mt-1">
      <div v-if="isLoading" class="m-4 d-flex">
        <b-spinner variant="primary" class="mx-auto" />
      </div>
      <div
        v-else-if="isDeleting"
        class="my-4 w-100 d-flex align-items-center justify-content-center flex-column"
      >
        <h2 class="title mt-1 mb-2">
          {{ $t("backoffice.subscriptions.form.delete-title") }}
          {{ item.name[selectedLanguage] }}
        </h2>
        <p>{{ $t("backoffice.subscriptions.form.delete-text") }}</p>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="m-1"
            @click="isDeleting = false"
          >
            {{ $t("backoffice.subscriptions.form.action.no-delete") }}
          </b-button>
          <b-button
            class="m-1"
            variant="outline-danger"
            @click="handleDeleteItem()"
          >
            {{ $t("backoffice.subscriptions.form.action.sure-delete") }}
          </b-button>
        </div>
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          lg="6"
          xl="4"
          class="d-flex mt-1 align-items-start justify-content-center"
        >
          <plans-card
            :item="item"
            :discount="discount"
            :currency="currentCurrency"
            :language="selectedLanguage"
          />
        </b-col>
        <b-col
          cols="12"
          lg="6"
          xl="8"
          class="d-flex align-items-center justify-content-center"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
            class="w-100 mr-lg-1"
          >
            <b-form
              @submit.prevent="
                item.key
                  ? handleSubmit(handleEditItem)
                  : handleSubmit(handleCreateItem)
              "
            >
              <h4 class="mb-50">Información general</h4>
              <!-- Nombre suscripción -->
              <b-row>
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="validationContext"
                    name=" "
                    rules="required"
                  >
                    <b-form-group label="Name" label-for="name" small>
                      <b-form-input
                        id="name"
                        v-model="item.name[selectedLanguage]"
                        type="text"
                        :placeholder="$t('form.type-placeholder')"
                        maxlength="50"
                        required
                        rules="required"
                        :state="getValidationState(validationContext)"
                        @input="handleInput(selectedLanguage, $event)"
                        @keydown.enter.prevent="onEnter"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Roles -->
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="validationContext"
                    name=" "
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('backoffice.subscriptions.form.role')"
                      label-for="role"
                    >
                      <b-form-select
                        id="role"
                        v-model="selectedRole"
                        :options="roles"
                        :placeholder="
                          $t('backoffice.subscriptions.form.role-placeholder')
                        "
                        label-type="text"
                        required
                        @keydown.enter.prevent="onEnter"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Tipo de intervalo -->
                <b-col
                  cols="12"
                  :lg="selectedInterval === 'recurrent' ? '4' : '12'"
                >
                  <validation-provider #default="validationContext" name=" ">
                    <b-form-group
                      :label="
                        $t('backoffice.subscriptions.form.interval-title')
                      "
                      label-for="interval"
                    >
                      <b-form-select
                        id="interval"
                        v-model="selectedInterval"
                        :options="intervalOptions"
                        :placeholder="$t('form.type-placeholder')"
                        label-type="text"
                        @keydown.enter.prevent="onEnter"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6" lg="4">
                  <validation-provider #default="validationContext" name=" ">
                    <b-form-group
                      v-if="selectedInterval === 'recurrent'"
                      :label="
                        $t('backoffice.subscriptions.form.recurrent-type')
                      "
                      label-for="typeRecurrentPayment"
                    >
                      <b-form-select
                        id="typeRecurrentPayment"
                        v-model="selectedRecurrent"
                        :options="recurrentOptions"
                        :placeholder="$t('form.type-placeholder')"
                        label-type="text"
                        @change="
                          selectedRecurrent === 'year'
                            ? (item.intervalCount = 1)
                            : ''
                        "
                        @keydown.enter.prevent="onEnter"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6" lg="4">
                  <validation-provider #default="validationContext" name=" ">
                    <b-form-group
                      v-if="selectedInterval === 'recurrent'"
                      :label="
                        $t('backoffice.subscriptions.form.recurrent-title')
                      "
                      label-for="recurrentPayment"
                    >
                      <b-form-input
                        id="recurrentPayment"
                        v-model="item.intervalCount"
                        type="number"
                        :disabled="selectedRecurrent === 'year'"
                        :placeholder="$t('form.type-placeholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Precio suscripción -->
                <b-col :cols="item.hasTaxes ? '5' : '6'">
                  <validation-provider
                    #default="validationContext"
                    name=" "
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('backoffice.subscriptions.form.price')"
                      label-for="price"
                    >
                      <b-form-input
                        id="priceInput"
                        v-model="item.total"
                        type="number"
                        step="0.01"
                        required
                        rules="required"
                        :state="getValidationState(validationContext)"
                        name=" "
                        placeholder="Precio de la suscripción"
                        aria-describedby="precioIva"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Currency -->
                <b-col :cols="item.hasTaxes ? '4' : '6'">
                  <validation-provider
                    v-if="currencyCode"
                    #default="validationContext"
                    name=" "
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('backoffice.subscriptions.form.currency')"
                      label-for="role"
                    >
                      <b-form-select
                        id="role"
                        v-model="selectedCurrency"
                        :options="currencyCode"
                        :placeholder="$t('form.type-placeholder')"
                        label-type="text"
                        @keydown.enter.prevent="onEnter"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- IVA -->
                <b-col v-if="item.hasTaxes" cols="3">
                  <validation-provider
                    #default="validationContext"
                    name=" "
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('backoffice.subscriptions.form.taxes')"
                      label-for="iva"
                    >
                      <b-form-input
                        id="iva"
                        v-model="item.vatPercentage"
                        type="number"
                        required
                        rules="required"
                        :state="getValidationState(validationContext)"
                        name=" "
                        :placeholder="$t('backoffice.subscriptions.form.taxes')"
                        aria-describedby="precioIva"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <b-checkbox
                    id="published"
                    v-model="item.hasTaxes"
                    class="mb-2"
                  >
                    {{ $t("backoffice.subscriptions.form.has-taxes") }}
                  </b-checkbox>
                </b-col>
                <b-col
                  v-if="item.hasTaxes"
                  cols="12"
                  md="6"
                  class="d-flex justify-content-end"
                >
                  <!-- Precio con Impuestos -->
                  <small
                    v-if="
                      !item.total ||
                      !item.vatPercentage ||
                      (+item.vatPercentage === 0 && +item.total === 0)
                    "
                    id="precioIva"
                    class="form-text text-primary mb-2"
                    >{{ $t("backoffice.subscriptions.form.price-taxes") }}: 0
                    <span>{{ currencyCode[selectedCurrency].symbol }} </span>
                    <!--  <span v-else>€</span> -->
                  </small>
                  <small
                    v-else
                    id="precioIva"
                    class="form-text text-primary mb-2"
                    >{{ $t("backoffice.subscriptions.form.price-taxes") }}:
                    {{ subtotal }}
                    <span
                      v-if="selectedInterval && selectedRecurrent === 'month'"
                    >
                      /
                      <span v-if="item.intervalCount && item.intervalCount > 1"
                        >{{ item.intervalCount }}
                        {{ $t("onboarding.subscriptions.months") }}</span
                      ><span v-else>{{
                        $t("onboarding.subscriptions.month")
                      }}</span>
                    </span>
                    <span
                      v-if="selectedInterval && selectedRecurrent === 'year'"
                    >
                      / {{ $t("onboarding.subscriptions.year") }}
                    </span>
                  </small>
                </b-col>
              </b-row>

              <!-- Nombre Descripción -->
              <validation-provider #default="validationContext" name=" ">
                <b-form-group
                  :label="$t('backoffice.subscriptions.form.description')"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="item.description[selectedLanguage]"
                    type="text"
                    rows="5"
                    maxlength="500"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('form.type-placeholder')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <h4 class="mb-50 mt-2">Descuentos</h4>
              <b-form-group
                label="Descuentos que se pueden aplicar"
                label-for="discountType"
                class="w-100"
              >
                <v-select
                  id="discountType"
                  v-model="selectedDiscounts"
                  searchable
                  multiple
                  :options="discounts"
                  label="name"
                  :get-option-label="(discount) => discount.name[currentLocale]"
                  placeholder="
                      No hay ningún descuento aplicado.
                    "
                />
              </b-form-group>
              <b-form-group
                label-for="allowMultipleDiscounts"
                label="Descuentos múltiples"
              >
                <b-form-checkbox
                  id="allowMultipleDiscounts"
                  v-model="item.allowMultipleDiscounts"
                  required
                >
                  <p class="ml-50">
                    El usuario puede aplicar múltiples descuentos a este plan
                  </p>
                </b-form-checkbox>
              </b-form-group>
              <!-- Is Test -->
              <h4 class="mb-50 mt-2">Ajustes avanzados</h4>
              <b-form-group
                v-if="
                  ['Event', 'Project', 'Course'].includes(community.mainType) &&
                  community.modality === 'hybrid'
                "
                label="Modalidad en la que aplicar este plan"
                label-for="modality"
                class="w-100"
              >
                <v-select
                  id="modality"
                  v-model="selectedModality"
                  :searchable="false"
                  :options="modalityOptions"
                  label="name"
                  :reduce="(modality) => modality.code"
                />
              </b-form-group>
              <b-form-group
                label-for="isTest"
                :label="$t('backoffice.subscriptions.form.is-test')"
              >
                <b-form-checkbox
                  id="isTest"
                  v-model="item.isTest"
                  :unchecked-value="false"
                  required
                >
                  <p class="ml-50">
                    Este plan se podrá pagar con el modo de prueba
                  </p>
                </b-form-checkbox>
              </b-form-group>
              <b-row>
                <b-col cols="12" />
              </b-row>
              <div class="float-right mb-1">
                <!-- Missing to implement BE -->
                <b-button
                  v-if="item.key"
                  variant="outline-danger"
                  @click="isDeleting = true"
                >
                  <span class="mx-1">
                    {{
                      $t("backoffice.subscriptions.form.action.delete")
                    }}</span
                  >
                </b-button>
                <b-button
                  variant="primary"
                  class="ml-2"
                  type="submit"
                  :disabled="Object.values(item.name).length === 0 || isSending"
                >
                  <span v-if="isSending"
                    ><b-spinner
                      label="Sending..."
                      variant="white"
                      class="mr-50"
                      small
                    />
                    {{ $t("backoffice.subscriptions.form.action.submit") }}
                  </span>
                  <span v-else class="mx-2">{{ $t("form.actions.save") }}</span>
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import { required } from "@validations";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import Service from "@/config/service-identifiers";
import vSelect from "vue-select";
import PlansCard from "@/@core/components/plans/PlansCard.vue";
import { fractionPrice } from "@/store/collective/plans-helpers";

export default {
  name: "SubscriptionsCreateModal",

  components: {
    ValidationProvider,
    ValidationObserver,
    PlansCard,
    LanguageSelectorHeader,
    vSelect,
  },
  mixins: [ToastNotificationsMixin, WidgetLayoutMixin],
  props: {
    itemToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      item: {},
      initItem: {
        name: {},
        subtotal: 0,
        description: {},
        vatPercentage: 21,
        isTest: false,
        roleKey: "10d4f8b9-20b5-4257-bcf9-ce1aae13904e",
        currencyId: 45,
        isCreating: true,
        interval: null,
        intervalCount: 1,
        total: 0,
        hasTaxes: true,
        allowMultipleDiscounts: false,
      },
      subscriptionInput: {},
      results: [],
      required,
      isLoading: false,
      isDeleting: false,
      isSending: false,
      selectedLanguage: "",
      rolesItems: [],
      selectedRole: "10d4f8b9-20b5-4257-bcf9-ce1aae13904e",
      selectedInterval: null,
      intervalOptions: [
        {
          value: null,
          text: this.$t("backoffice.subscriptions.form.single-payment"),
        },
        {
          value: "recurrent",
          text: this.$t("backoffice.subscriptions.form.recurrent-payment"),
        },
      ],
      selectedRecurrent: "month",
      recurrentOptions: [
        {
          value: "month",
          text: this.$t("backoffice.subscriptions.form.month-payment"),
        },
        {
          value: "year",
          text: this.$t("backoffice.subscriptions.form.year-payment"),
        },
      ],
      selectedCurrency: 45,
      currencyCode: [{ value: null, text: "Select a Currency..." }],
      selectedDiscounts: [],
      selectedModality: null,
    };
  },
  computed: {
    discountTypeOptions() {
      return [
        { text: "Descuento con porcentaje", value: "cent" },
        { text: "Descuento con precio", value: "number" },
      ];
    },
    modalityOptions() {
      return [
        { name: this.$t("events.event-details.online"), code: 1 },
        { name: this.$t("events.event-details.on-site"), code: 2 },
        { name: "En línia y presencial", code: null },
      ];
    },
    discount() {
      if (this.selectedDiscounts.length > 0) {
        return this.selectedDiscounts[this.selectedDiscounts.length - 1];
      }
      return null;
    },
    currentCurrency() {
      return this.currencyCode[this.selectedCurrency];
    },
    subtotal() {
      const currentCurrency = this.currencyCode[this.selectedCurrency].symbol;
      const hasPricesOrTax = !this.item.total || !this.item.vatPercentage;

      if (hasPricesOrTax) {
        return `0.00 ${currentCurrency}`;
      }

      const vatPercentageCalculated = 0 + this.item.vatPercentage / 100;
      return fractionPrice(
        this.item.total - this.item.total * vatPercentageCalculated,
        currentCurrency
      );
    },
    locales() {
      return this.$store.getters.locales;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    roles() {
      if (this.$store.getters.roles) {
        const roles = this.$store.getters.roles.unpaginated;
        this.rolesItems = [];
        for (const indexRole in roles) {
          const newValue = {
            value: roles[indexRole].key,
            text: roles[indexRole].name,
            ...roles[indexRole],
          };
          this.rolesItems.push(newValue);
        }
        return this.rolesItems;
      }
    },
    discounts() {
      return this.$store.getters.coupons.unpaginated;
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  async created() {
    this.isLoading = true;
    this.restarItem();
    this.selectedLanguage = this.currentLocale;
    await this.getRoles();
    await this.fetchCode();
    await this.getInitialData();
    this.selectedRole = this.item.roleKey;
    this.selectedInterval = this.item.interval
      ? "recurrent"
      : this.item.interval;
    this.selectedRecurrent = this.item.interval ? this.item.interval : "month";
    this.isLoading = false;
  },

  methods: {
    openModal() {
      this.item = {};
      if (this.itemToEdit) {
        this.item = { ...this.itemToEdit };
        this.item.name = { ...this.itemToEdit.name };
        this.item.description = { ...this.itemToEdit.description };
        this.selectedCurrency = this.item.currencyId;
        this.selectedDiscounts = [...this.itemToEdit.discounts];
        this.selectedModality = this.item.modality;
      } else {
        this.restarItem();
      }
    },
    restarItem() {
      this.item = {};
      this.item = { ...this.initItem };
      this.item.name = {};
      this.item.description = {};
      this.selectedModality = null;
      this.selectedCurrency = 45;
      this.selectedDiscounts = [];
    },
    closeModal() {
      this.$bvModal.hide(`modal-create-plans`);
    },
    async handleInput(locale, localeValue) {
      this.$emit("input", { ...this.item, [locale]: localeValue });
    },
    async getInitialData() {
      try {
        await this.$store.dispatch("getItems", {
          itemType: "coupons",
          forceAPICall: true,
          perPage: 1000,
          requestConfig: {
            modelType: this.community.mainType?.toLowerCase(),
          },
        });
      } catch {
        this.notifyError(this.$t("error-message.load-failed"));
      }
    },
    onEnter() {
      this.$emit("input", [...this.info]);
    },

    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async getRoles() {
      await this.$store.dispatch("getRoles", {
        communitySlug: this.community.slug,
        page: 1,
        perPage: 200,
      });
    },
    async fetchCode() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "currencies",
        {
          params: {
            communityKey: this.community.key,
            count: 164,
            forceAPICall: true,
          },
        }
      );
      const currencyArray = response.data.data;
      for (const indexCurrency in currencyArray) {
        const newCurrency = {
          value: currencyArray[indexCurrency].id,
          text: `${currencyArray[indexCurrency].ISO} (${currencyArray[indexCurrency].symbol})`,
          ...currencyArray[indexCurrency],
        };
        this.currencyCode?.push(newCurrency);
      }
    },
    async handleCreateItem() {
      this.isSending = true;

      const requestConfig = {
        name: this.item.name,
        total: +this.item.total,
        description: this.item.description,
        interval:
          this.selectedInterval === "recurrent"
            ? this.selectedRecurrent
            : this.selectedInterval,
        intervalCount: +this.item.intervalCount,
        isTest: this.item.isTest,
        vatPercentage: +this.item.vatPercentage,
        roleKey: this.selectedRole,
        hasTaxes: this.item.hasTaxes,
        currencyId: this.selectedCurrency,
        couponKeys: [],
      };
      if (this.selectedDiscounts.length > 0) {
        this.selectedDiscounts.forEach((discount) =>
          requestConfig.couponKeys.push(discount.key)
        );
        requestConfig.applyDiscountsDirectly = true;
        requestConfig.allowMultipleDiscounts = this.item.allowMultipleDiscounts;
      }
      if (this.community.modality && this.selectedModality) {
        requestConfig.modality = this.selectedModality;
      }
      try {
        await this.$store.dispatch("createItemPayments", {
          itemType: "subscriptionPlans",
          item: { ...requestConfig },
        });
        this.$emit("fetchData");
        this.closeModal();
        this.notifySuccess(this.$t("success-message.general-success-create"));

        this.isSending = false;
      } catch (error) {
        this.notifyError(this.$t("error-message.general-error"));
        this.isSending = false;
      }
    },

    async handleDeleteItem() {
      try {
        await this.$store.dispatch("unshareItemPayments", {
          itemType: "subscriptionPlans",
          item: {
            key: this.item.key,
          },
        });
        this.$emit("fetchData");
        this.closeModal();
        this.notifySuccess(this.$t("success-message.general-success-delete"));

        this.isDeleting = false;
      } catch (error) {
        this.notifyError(this.$t("error-message.general-error"));
        this.isDeleting = false;
      }
    },
    async handleEditItem() {
      this.isSending = true;
      try {
        const requestConfig = {
          key: this.item.key,
          name: this.item.name,
          total: +this.item.total,
          description: this.item.description,
          interval:
            this.selectedInterval === "recurrent"
              ? this.selectedRecurrent
              : this.selectedInterval,
          intervalCount: +this.item.intervalCount,
          isTest: this.item.isTest,
          vatPercentage: +this.item.vatPercentage,
          roleKey: this.selectedRole,
          hasTaxes: this.item.hasTaxes,
          currencyId: this.selectedCurrency,
          couponKeys: [],
        };

        if (this.selectedDiscounts.length > 0) {
          this.selectedDiscounts.forEach((discount) =>
            requestConfig.couponKeys.push(discount.key)
          );
          requestConfig.applyDiscountsDirectly = true;
          requestConfig.allowMultipleDiscounts =
            this.item.allowMultipleDiscounts;
        }
        if (this.community.modality && this.selectedModality) {
          requestConfig.modality = this.selectedModality;
        }
        await this.$store.dispatch("editItemsPayments", {
          itemType: "subscriptionPlans",
          item: { ...requestConfig },
        });
        this.$emit("fetchData");
        this.notifySuccess(this.$t("success-message.general-success-edit"));
        this.closeModal();

        this.isSending = false;
      } catch {
        this.notifyError(this.$t("error-message.general-error"));
        this.isSending = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.price-container {
  min-height: 65.8px;
}
.flag {
  width: 100px;
  height: 32px;
  margin: 0 auto;
  padding-top: 8px;
  position: absolute;
  top: 0;
  right: 21px;
  background: $primary;
  color: white;
  > p {
    line-height: 1.2rem;
  }
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
}
.v-select {
  min-height: 38px !important;
  > #vs1__combobox {
    min-height: 38px !important;
  }
}
#vs1__combobox {
  min-height: 38px !important;
}
</style>
