<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap mt-1">
        <h4 class="mb-1">
          {{ $t("backoffice.subscriptions.title") }}
        </h4>
      </div>
      <div class="mt-2 mb-5">
        <b-row>
          <!-- ** ORDER ** -->
          <b-col
            v-for="item in items"
            :key="item.key"
            class="flex-column d-flex align-items-center"
          >
            <plans-small-card
              :item="item"
              :space="community"
              @openModal="openModal"
            />
          </b-col>
          <b-col v-if="items.length < 14">
            <b-card
              no-body
              class="new-subscription small-card flex-column align-items-center justify-content-center pointer"
              border-variant="primary"
              bg-variant="light"
              @click="openModal()"
            >
              <h1 class="font-large-5 text-center text-primary">+</h1>
              <p class="font-medium-1 text-center text-primary">
                {{ $t("backoffice.subscriptions.add") }}
              </p>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <subscription-create-modal
      :item-to-edit="subscription"
      @fetchData="fetchData"
    />
  </backoffice-container>
</template>

<script>
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import SubscriptionCreateModal from "@/backoffice/components/SubscriptionCreateModal.vue";
import { totalCard } from "@/store/collective/plans-helpers";
import PlansSmallCard from "@/@core/components/plans/PlansSmallCard.vue";

export default {
  name: "BackofficeSubscriptions",
  components: {
    BackofficeContainer,
    SubscriptionCreateModal,
    PlansSmallCard,
  },
  data() {
    return {
      isLoading: true,
      subscription: {},
    };
  },
  computed: {
    items() {
      if (!this.$store.getters.subscriptionPlans) return [];

      return this.$store.getters.subscriptionPlans.unpaginated;
    },
    discounts() {
      if (this.items.length > 0) {
        const discounts = {};
        this.items.forEach((plan) => {
          if (plan.discounts.length > 0) {
            discounts[plan.key] = plan.discounts[0];
          }
        });
        return discounts;
      }
      return null;
    },

    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    totalCard(item) {
      return totalCard(item);
    },
    translate(field) {
      return translateTranslationTable(this.selectedLanguage, field);
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch("getItemsPayments", {
        itemType: "subscriptionPlans",
        page: 1,
        perPage: 16,
        requestConfig: {
          orderBy: -1,
          count: 16,
        },
      });
      this.isLoading = false;
    },
    openModal(item) {
      this.subscription = item;
      this.$bvModal.show(`modal-create-plans`);
    },
    onEnter() {
      this.$emit("input", [...this.info]);
    },
  },
};
</script>

<style lang="scss" scoped>
.new-subscription {
  border-style: dashed !important;
  border-width: 3px !important;
}
.small-card {
  min-height: 19.4rem;
  height: auto !important;
}
</style>
